'use client';

import { forwardRef } from 'react';
import { Control, Controller, FieldValue, FieldValues } from 'react-hook-form';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Icon, Portal, SelectProps, Value } from '@radix-ui/react-select';

import * as S from './styles';

export type item = {
  label: string | number;
  value: string;
  icon?: React.ReactNode;
};

export type ISelect = {
  items: item[];
  label?: string;
  name: string;
  classNameWrapper?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  control?: Control<FieldValue<FieldValues>>;
  onChange?: (value: string) => void;
} & SelectProps;

const Select = forwardRef<HTMLDivElement, ISelect>(
  (
    {
      items,
      label,
      classNameWrapper,
      name,
      placeholder,
      error,
      errorMessage,
      control,
      ...props
    },
    ref
  ) => {
    return (
      <div ref={ref} className={classNameWrapper}>
        <>
          {label && <S.Label htmlFor={name}>{label}</S.Label>}
          <Controller
            defaultValue={props.defaultValue}
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <S.SelectRoot
                  value={field.value}
                  {...props}
                  defaultValue={field.value}
                  onValueChange={props?.onChange || field?.onChange}>
                  <S.SelectTrigger aria-label={label} value={field.value}>
                    <Value placeholder={placeholder} aria-label={label} />
                    <Icon className="selectIcon">
                      <KeyboardArrowDownIcon />
                    </Icon>
                  </S.SelectTrigger>
                  <Portal>
                    <S.SelectContent>
                      <S.ViewPort>
                        {!props.disabled &&
                          items?.length > 0 &&
                          items?.map((item, index) => (
                            <S.SelectItem key={index} value={item.value}>
                              <S.SelectLabel>{item.label}</S.SelectLabel>
                            </S.SelectItem>
                          ))}
                      </S.ViewPort>
                    </S.SelectContent>
                  </Portal>
                </S.SelectRoot>
              );
            }}
          />

          {error && errorMessage && (
            <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
          )}
        </>
      </div>
    );
  }
);

Select.displayName = 'Select';

export { Select };
