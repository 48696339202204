'use client';

import { ButtonHTMLAttributes, FC } from 'react';

import { LinkProps } from 'next/link';

import clsx from 'clsx';

import * as S from './styles';

type ButtonLinkProps = ButtonHTMLAttributes<HTMLButtonElement> | LinkProps;

type LinkButtonProps = {
  href?: string;
  size?: 'sm' | 'md';
  children: React.ReactNode;
  isBlank?: boolean;
} & ButtonLinkProps;

const LinkButton: FC<LinkButtonProps> = ({
  children,
  href = '',
  size = 'sm',
  isBlank = false,
  ...props
}) => {
  const isButton = !href;
  const linkProps = props as LinkProps;
  const buttonProps = props as ButtonHTMLAttributes<HTMLButtonElement>;

  return isButton ? (
    <S.ButtonComponent className={clsx(`size-${size}`, buttonProps.className)}>
      {children}
    </S.ButtonComponent>
  ) : (
    <S.LinkComponent
      {...linkProps}
      target={isBlank ? '_blank' : '_self'}
      prefetch={false}
      className={clsx(`size-${size}`, buttonProps.className)}
      href={href}
      pointer-events={buttonProps.disabled}>
      {children}
    </S.LinkComponent>
  );
};

export { LinkButton };
