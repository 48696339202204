'use client';

import { ReactNode } from 'react';

import Link from 'next/link';

import { Status } from '@/utils/enums';
import { Close } from '@radix-ui/react-dialog';
import clsx from 'clsx';

import {
  Badge,
  Button,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalPortal,
  ModalRoot,
  ModalTrigger,
} from '@/components';
import { ButtonStyleProps } from '@/components/button/styles';

import * as S from './styles';

type StatusModal = Exclude<
  Status,
  'info' | 'inProgress' | 'complete' | 'refunded'
>;

type ModalProps = {
  status: StatusModal;
  title: string;
  description: string;
  primaryButton?: string;
  secondaryButton?: string;
  contentPosition?: 'center' | 'left';
  TriggerButton: ReactNode;
  defaultOpen?: boolean;
  uniqueButton?: boolean;
  typeUniqueButton?: StatusModal;
  handleCloseModal?: () => void;
};

const getStatusColor: Record<
  string,
  {
    color: ButtonStyleProps['color'];
    status: Status;
  }
> = {
  success: {
    color: 'primary',
    status: 'success',
  },
  warning: {
    color: 'primary',
    status: 'warning',
  },
  error: {
    color: 'primary-error',
    status: 'error',
  },
};

const ModalInfo = ({
  status,
  title,
  description,
  primaryButton,
  secondaryButton,
  contentPosition = 'left',
  TriggerButton,
  defaultOpen = false,
  uniqueButton,
  typeUniqueButton,
  handleCloseModal,
}: ModalProps) => {
  const containerClassName = clsx(
    `position-${contentPosition}`,
    `status-${status}`
  );

  return (
    <ModalRoot defaultOpen={defaultOpen} onOpenChange={handleCloseModal}>
      <ModalTrigger asChild>{TriggerButton}</ModalTrigger>

      <ModalPortal>
        <>
          <ModalOverlay data-testid="overlay" />

          <ModalContent className={containerClassName}>
            <S.WrapperBadge className={containerClassName}>
              <Badge
                size="lg"
                variations="iconBadge"
                status={getStatusColor[status].status}
              />
            </S.WrapperBadge>

            <S.TitleComponent className={containerClassName}>
              {title}
            </S.TitleComponent>
            {description !== 'creation failed' && (
              <S.DescriptionComponent className={containerClassName}>
                {description}
              </S.DescriptionComponent>
            )}
            {description === 'creation failed' && (
              <S.DescriptionComponent className={containerClassName}>
                Entre em contato com nosso time através do número{' '}
                <Link href="https://wa.me/5531999057881">(31)99905-7881</Link>{' '}
                no WhatsApp para liberar sua conta.
              </S.DescriptionComponent>
            )}
            <S.ContainerButtons>
              {uniqueButton ? (
                <Close asChild>
                  <Button
                    autoFocus
                    type="button"
                    size="lg"
                    color={
                      getStatusColor[typeUniqueButton as StatusModal].color
                    }>
                    {primaryButton || 'Confirmar'}
                  </Button>
                </Close>
              ) : (
                <>
                  <Button
                    autoFocus
                    type="button"
                    size="lg"
                    color={getStatusColor[status].color}>
                    {primaryButton || 'Confirmar'}
                  </Button>

                  <Button color="white" type="button" size="lg">
                    {secondaryButton || 'Cancelar'}
                  </Button>
                </>
              )}
            </S.ContainerButtons>

            <ModalCloseButton asChild />
          </ModalContent>
        </>
      </ModalPortal>
    </ModalRoot>
  );
};

export { ModalInfo };
