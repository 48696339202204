import { HTMLAttributes } from 'react';

import clsx from 'clsx';

import * as S from './styles';

type DividerProps = {
  variant?: 'primary' | 'secondary' | 'tertiary';
} & HTMLAttributes<HTMLDivElement>;

const Divider = ({
  variant = 'primary',
  className,
  ...props
}: DividerProps) => {
  const containerClassName = clsx(`
    variant-${variant}
  `);
  return (
    <S.WrapperDivider
      {...props}
      className={clsx(containerClassName, className)}
      data-testid="divider">
      {variant === 'primary' && (
        <>
          <S.Divider />
          <S.Text> ou </S.Text>
          <S.Divider />
        </>
      )}

      {variant === 'secondary' && (
        <>
          <S.Divider />
        </>
      )}

      {variant === 'tertiary' && <S.DividerDot />}
    </S.WrapperDivider>
  );
};

export { Divider };
