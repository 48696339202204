import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing['04']};
  `}
`;

export const ImageCanvas = styled.label`
  ${({ theme }) => css`
    width: 100%;
    height: 250px;
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.gray[300]};
    border: ${theme.spacing['02']} dashed ${theme.colors.gray[300]};
    border-radius: ${theme.radius.xs};
    cursor: pointer;
    transition: color 300ms ease-in-out, background 300ms ease-in-out;
    outline: none;
    overflow: hidden;
  `}
`;

export const NoImageText = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${theme.spacing['04']};

    .instruction {
      color: ${theme.colors.gray[600]};
      font-weight: ${theme.typography.fontWeight.medium};
      font-size: ${theme.typography.fontSize[14].size};
    }

    .supported-images {
      color: ${theme.colors.gray[500]};
      font-weight: ${theme.typography.fontWeight.regular};
      font-size: ${theme.typography.fontSize[12].size};
    }

    .icon {
      width: ${theme.spacing[48]};
      height: ${theme.spacing[48]};
    }

    strong {
      color: ${theme.colors.gray[900]};
      text-decoration: underline;
      text-underline-offset: ${theme.spacing['04']};
      text-decoration-thickness: ${theme.spacing['02']};
      line-height: ${theme.typography.fontSize[20].lineHeight};
    }
  `}
`;

export const ImagePreview = styled.img`
  width: 100%;
`;

export const ImageInput = styled.input`
  display: none;
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error[700]};
    font-size: ${theme.typography.fontSize['14'].size};
    margin-top: ${theme.spacing['04']};
  `}
`;
