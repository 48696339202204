import {
  Popover as RadixPopover,
  PopoverContent as RadixPopoverContent,
  PopoverPortal as RadixPopoverPortal,
  PopoverTrigger as RadixPopoverTrigger,
} from '@radix-ui/react-popover';
import styled, { css } from 'styled-components';

import { Button } from '../button';

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => css`
    &.button {
      background-color: ${theme.colors.gray[100]};
    }
  `}
`;

export const LabelContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing['06']};
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    & button {
      appearance: none;
      color: ${theme.colors.gray['400']};
      font-size: ${theme.typography.fontSize['12'].size};
      font-weight: ${theme.typography.fontWeight.regular};
      line-height: ${theme.typography.fontSize['12'].lineHeight};
      margin-left: ${theme.spacing['08']};
      display: inline-flex;
      align-items: center;
    }
  `}
`;
export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.gray['900']};
    font-size: ${theme.typography.fontSize['14'].size};
    font-weight: ${theme.typography.fontWeight.medium};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
  `}
`;

export const Popover = styled(RadixPopover)``;
export const WrapperContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    border-radius: ${theme.radius.sm};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    ${theme.breakpoints.tablet960} {
      flex-direction: row;
      align-items: center;
    }

    .rdp-caption_label {
      text-transform: capitalize;
    }

    .rdp-head_cell {
      font-size: 0;
    }

    .rdp-head_cell::first-letter {
      font-size: 14px;
    }

    & > .dataContent {
      background-color: white;

      & > .react-day-picker__head-cell::first-letter {
        display: initial;
      }

      & > .react-day-picker__caption {
        text-transform: uppercase;
      }
    }
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.gray[100]};
    border-radius: ${theme.radius.md};
  `}
`;
export const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    width: 100%;
    ${({ theme }) => css`
      border-top: 1px solid ${theme.colors.gray[300]};
      background-color: ${theme.colors.white};
      padding: ${theme.spacing['12']};
    `}

    & > .Button-apply {
      display: flex;
      gap: ${theme.spacing['12']};
    }
  `}
`;
export const SubTitle = styled.span`
  display: inline-block;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeight.semiBold};
    color: ${theme.colors.gray[900]};
    margin-left: ${theme.spacing['12']};
  `}
`;

export const ButtonWrapper = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-weight: normal;
  width: 100%;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSize['16'].size};
    color: ${theme.colors.gray[400]}!important;
    gap: ${theme.spacing['08']};
  `}
`;
export const PopoverTrigger = styled(RadixPopoverTrigger)``;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const PopoverPortal = styled(RadixPopoverPortal)``;
export const PopoverContent = styled(RadixPopoverContent)`
  width: 100%;
  padding: 0;
  text-align: end;
  z-index: 9;
  &.data {
    background-color: red;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.mobile360} {
      flex-direction: row;
    }
  `}
`;

export const WrapperPeriod = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 320px;
  height: 404px;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  ${({ theme }) => css`
    border-top-left-radius: 0;
    ${theme.breakpoints.tablet960} {
      flex-direction: column;
      border-top-left-radius: ${theme.radius.md};
    }
  `}
`;
export const WrapperPeriodIntern = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  max-width: 320px;
  align-items: center;
  ${({ theme }) => css`
    gap: ${theme.spacing['08']};
    height: 100%;
    padding-left: 425px;
    ${theme.breakpoints.tablet960} {
      border-top-left-radius: ${theme.radius.md};
      padding-left: ${theme.spacing['16']};
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
    }
  `}
  .periodButton {
    display: flex;
    background-color: transparent;
    border: none;
    align-items: flex-start;
    ${({ theme }) => css`
      font-weight: ${theme.typography.fontWeight.regular};
    `}
  }
`;

export const ButtonRange = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing['08']};
  `}
`;

export const ButtonFooter = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.spacing['08']};
  `}
`;

export const FooterButton = styled.div`
  ${({ theme }) => css`
    display: grid;
    align-items: center;
    border-top-right-radius: ${theme.radius.sm};
    border: 1px solid ${theme.colors.gray[300]};
    box-shadow: ${theme.shadows.xl};
  `}
`;
