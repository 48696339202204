import styled, { css } from 'styled-components';

export const WrapperDivider = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  &.variant-secondary {
    gap: unset;
  }
`;

export const Divider = styled.hr`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray['300']};
    border: none;
    height: 1px;
    width: 100%;
  `}
`;

export const Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.gray['400']};
    font-size: ${theme.typography.fontSize['14'].size};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
    font-weight: ${theme.typography.fontWeight.medium};
  `}
`;

export const DividerDot = styled.hr`
  ${({ theme }) => css`
    border-top: 1px dashed ${theme.colors.gray['300']};
    border-bottom: 0;
    width: 100%;
  `}
`;
