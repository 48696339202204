import Link from 'next/link';

import styled, { css } from 'styled-components';

import { Button } from '@/components';
export const NavContainer = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.tablet960} {
      display: none;
    }

    & .menucursor {
      cursor: pointer;
    }
  `}
`;

export const ButtonPress = styled.button`
  ${({ theme }) => css`
    padding: ${theme.spacing['08']} ${theme.spacing[12]};
    font-weight: ${theme.typography.fontWeight.bold};
    height: ${theme.spacing[24]};
    color: ${theme.colors.gray[900]};
    font-size: ${theme.typography.fontSize['16'].size};
    font-weight: ${theme.typography.fontWeight.medium};
    border-radius: ${theme.radius.xs};

    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  `}
`;

export const NavMenu = styled.nav`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.white};
    position: fixed;
    overflow: hidden;
    gap: ${theme.spacing[16]};
    padding: ${theme.spacing[12]};
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;

    & > .wrapperInfoUser {
      display: flex;
      flex-direction: column;
      background-color: ${theme.colors.gray[100]};
      margin: -${theme.spacing['12']};
      padding: ${theme.spacing['16']} ${theme.spacing['12']};
      justify-content: center;
      gap: ${theme.spacing['08']};
      color: ${theme.colors.gray[900]};

      & > .wrapperOrganization {
        display: flex;
        justify-content: center;
      }

      & > .wrapperProject {
        button {
          display: flex;
          margin: 0 auto;
        }
      }
    }
  `}
`;

export const ButtonClose = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: ${theme.colors.black};
    cursor: pointer;
  `}
`;

export const DashboardButton = styled(Link)`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSize['16'].size};
    font-weight: ${theme.typography.fontWeight.medium};
    color: ${theme.colors.gray[900]};
    padding: ${theme.spacing[12]} ${theme.spacing[16]};
    border-radius: ${theme.radius.xs};
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.gray[100]};
    }
  `}
`;

export const ContentList = styled.div`
  ${({ theme }) => css`
    width: 100%;
    cursor: pointer;
  `}
`;

export const ListUl = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const ListLi = styled.li`
  ${({ theme }) => css`
    padding: ${theme.spacing[12]} ${theme.spacing[32]};
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[100]};
  }
`;

export const ListLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.gray[900]};
    font-weight: ${theme.typography.fontWeight.bold};
    margin-left: ${theme.spacing[16]};
  `}
`;

export const ListDescription = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray[500]};
    font-weight: ${theme.typography.fontWeight.regular};
    font-size: ${theme.typography.fontSize['14'].size};
  `}
`;

export const ProjectsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ProjectsButton = styled(Button)`
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
