'use client';
import { useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMediaQuery } from '@mui/material';
import { useTheme } from 'styled-components';

import * as S from './styles';

type PaginationProps = {
  limit?: number;
  total?: number;
  offset?: number;
  className?: string;
  setOffset?: (offset: number) => void;
};

export function Pagination({
  limit = 10,
  total = 0,
  offset = 0,
  className,
  setOffset,
}: PaginationProps) {
  const [current, setCurrent] = useState(offset / limit + 1);
  const pages = Math.ceil(total / limit);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.tablet720);

  function onPageChange(page: number) {
    if (setOffset) {
      const newOffset = (page - 1) * limit;
      setOffset(newOffset);
      setCurrent(page);
    }
  }

  function renderPageNumbers() {
    if (pages <= 6) {
      return Array.from({ length: pages }).map((_, index) => index + 1);
    } else {
      const firstPages = [1];
      const lastPages = [pages];

      if (current <= 4) {
        return [...firstPages, 2, 3, 4, null, ...lastPages];
      } else if (current <= pages - 3) {
        return [
          ...firstPages,
          null,
          current - 1,
          current,
          current + 1,
          null,
          ...lastPages,
        ];
      } else {
        return [
          ...firstPages,
          null,
          pages - 3,
          pages - 2,
          pages - 1,
          ...lastPages,
        ];
      }
    }
  }

  return (
    <S.Wrapper className={className}>
      <S.StyledPagination className={'pagination'}>
        <S.List>
          <S.Button
            onClick={() => onPageChange(current - 1)}
            disabled={current === 1}
            className="paginationButton primary"
            aria-label="Anterior">
            {isDesktop ? (
              <div className="firstOne">
                <ArrowBackIcon data-testid="svgBack" /> Anterior
              </div>
            ) : (
              <ArrowBackIcon />
            )}
          </S.Button>
        </S.List>
        {renderPageNumbers().map((page, index) =>
          page === null ? (
            <S.List key={`ellipsis-${index}`}>
              <S.Button
                className={'paginationItem secondary '}
                aria-label="Ellipsis">
                ...
              </S.Button>
            </S.List>
          ) : (
            <S.List key={`page-${page}`}>
              <S.Button
                onClick={() => onPageChange(page)}
                className={
                  page === current
                    ? 'paginationItemActive secondary '
                    : 'paginationItem secondary '
                }
                aria-label={`Page ${page}`}>
                {page}
              </S.Button>
            </S.List>
          )
        )}
        <S.List>
          <S.Button
            onClick={() => onPageChange(current + 1)}
            disabled={current === pages}
            aria-disabled={current === pages}
            className="paginationButton tertiary"
            aria-label="Próximo">
            {isDesktop ? (
              <div className="firstOne">
                Proximo <ArrowForwardIcon data-testid="svgForward" />
              </div>
            ) : (
              <ArrowForwardIcon />
            )}
          </S.Button>
        </S.List>
      </S.StyledPagination>
    </S.Wrapper>
  );
}
