import { request } from '@/utils/request';

const url = process.env.NEXT_PUBLIC_BFF;

export type ListProjectsByUserProps = {
  organizationName: string;
  organizationId: string;
  projectId: string;
  projectName: string;
  role: string;
  jwt_token: string;
};

export const getListProjectsByUser = async (
  userId: string,
  organizationId: string,
  name?: string
) => {
  if (!userId) {
    return [];
  }

  const queryParams: { [key: string]: string } = {
    organizationId,
  };
  if (name) {
    queryParams.name = name;
  }
  const queryString = new URLSearchParams(queryParams).toString();
  return request<ListProjectsByUserProps[]>({
    url: url + '/project/organization/user/' + userId + '?' + queryString,
    method: 'GET',
  });
};
