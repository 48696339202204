'use client';

import { getUser } from '@/services/users/get-user';
import { UserProps } from '@/utils/enums/user';
import { RequestParamsErrorResponseProps } from '@/utils/request';
import { useMutation } from '@tanstack/react-query';

export const useSearchUser = () => {
  return useMutation({
    mutationKey: ['user'],
    mutationFn: async (firebaseId: string) => {
      const data = await getUser(firebaseId);

      return data as UserProps;
    },
    onError: (error: RequestParamsErrorResponseProps) => {
      return error;
    },
  });
};
