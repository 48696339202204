/* eslint-disable @typescript-eslint/ban-types */
'use client';

import React, { useState } from 'react';
import {
  Controller,
  Control,
  UseFormRegister,
  FieldValues,
  FieldValue,
} from 'react-hook-form';
import InputMask from 'react-input-mask';

import { Size } from '@/utils/enums';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import clsx from 'clsx';

import * as S from './styles';

interface InputProps {
  icon?: React.ReactNode;
  label?: string;
  labelSuffix?: string;
  placeholder?: string;
  size?: Size;
  error?: boolean;
  errorMessage?: string;
  prefix?: boolean;
  prefixText?: string;
  noBackground?: boolean;
  suffix?: boolean;
  suffixText?: string;
  defaultValue?: string;
  disabled?: boolean;
  password?: boolean;
  classNameWrapper?: string;
  name?: string;
  mask?: string;
  flatSide?: 'left' | 'right' | 'none';
  control?: Control<FieldValue<FieldValues>>;
  register?: UseFormRegister<FieldValue<FieldValues>>;
  fixedText?: string;
}

const Input = ({
  label,
  labelSuffix,
  placeholder,
  error,
  errorMessage,
  size = 'sm',
  disabled = false,
  prefix,
  prefixText = '',
  noBackground,
  suffix,
  suffixText = '',
  defaultValue,
  icon,
  password = false,
  classNameWrapper,
  name = 'input',
  mask,
  flatSide = 'none',
  control,
  register,
  fixedText,
  ...rest
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const isReactHookForm = !!register && !!name;
  const registerInfo = isReactHookForm && register(name);

  const commonClassName = clsx(`size-${size}`, `border-${flatSide}`, {
    error: error,
    disabled: disabled,
    noBackground: noBackground,
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const renderIcon = () =>
    icon && <div style={{ display: 'flex' }}>{icon}</div>;

  const renderInput = () => {
    if (mask) {
      return (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => {
            return (
              <InputMask
                mask={mask}
                disabled={disabled}
                value={field.value || ''}
                onChange={(e) => {
                  field.onChange(e);
                }}>
                <S.Input
                  role="textbox"
                  placeholder={placeholder}
                  className={commonClassName}
                  type={'text'}
                />
              </InputMask>
            );
          }}
        />
      );
    }

    return (
      <S.Input
        role="textbox"
        aria-label={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        className={commonClassName}
        type={password ? (showPassword ? 'text' : 'password') : 'text'}
        {...registerInfo}
        {...rest}
        onChange={(e) => {
          if (registerInfo && registerInfo.onChange) {
            registerInfo.onChange(e);
          }
        }}
      />
    );
  };
  return (
    <div className={classNameWrapper}>
      {label && (
        <S.Label htmlFor={name}>
          {label}
          {labelSuffix ? (
            <S.OptionalLabel>{labelSuffix}</S.OptionalLabel>
          ) : null}
        </S.Label>
      )}

      <S.InputContainer className={commonClassName}>
        {fixedText && (
          <S.FixedText className={commonClassName}>{fixedText}</S.FixedText>
        )}
        {prefix && (
          <S.PrefixLabel $isImage={!!icon} className={commonClassName}>
            {renderIcon()}
            {prefixText}
          </S.PrefixLabel>
        )}

        {renderInput()}

        {password && (
          <S.ButtonPassword
            type="button"
            data-testid="button-password"
            onClick={handleTogglePasswordVisibility}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </S.ButtonPassword>
        )}
        {suffix && (
          <S.SuffixLabel className={commonClassName}>
            {suffixText}
            {renderIcon()}
          </S.SuffixLabel>
        )}
      </S.InputContainer>
      {error && errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
    </div>
  );
};

export { Input };
