import { Root, TabsList, TabsTrigger, TabsContent } from '@radix-ui/react-tabs';
import styled, { css } from 'styled-components';

export const TabsRootStyled = styled(Root)`
  display: flex;

  &[data-orientation='horizontal'] {
    flex-direction: column;
  }
`;

export const TabsListStyled = styled(TabsList)`
  flex-shrink: 0;
  display: flex;

  &[data-orientation='vertical'] {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TabsTriggerStyled = styled(TabsTrigger)`
  ${({ theme }) => css`
    && {
      align-items: center;
      color: ${theme.colors.gray[400]};
      display: flex;
      flex: 1;
      font-family: inherit;
      height: ${theme.spacing['48']};
      justify-content: center;
      line-height: 1;
      user-select: none;
      gap: ${theme.spacing['08']};
      cursor: pointer;

      &.size-sm {
        font-size: ${theme.typography.fontSize[12].size};

        svg {
          height: ${theme.spacing[16]};
          width: ${theme.spacing[16]};
        }
      }

      &.size-md {
        font-size: ${theme.typography.fontSize[16].size};

        svg {
          height: ${theme.spacing[20]};
          width: ${theme.spacing[20]};
        }
      }

      &:hover {
        color: ${theme.colors.gray[700]};
      }

      &:focus {
        position: relative;
        box-shadow: 0 0 0 2px black;
      }

      &[data-orientation='horizontal'] {
        box-shadow: 0 2px 0 0 ${theme.colors.gray[300]};
      }

      &[data-state='active'] {
        color: ${theme.colors.gray[700]};
        box-shadow: inset 0 -2px 0 0 ${theme.newColors.btnPrimaryBg},
          0 2px 0 0 ${theme.newColors.btnPrimaryBg};
      }

      &[data-orientation='vertical'] {
        padding: ${theme.spacing[10]} 0 ${theme.spacing[10]}
          ${theme.spacing[16]};

        &[data-state='active'] {
          color: ${theme.colors.gray[700]};
          box-shadow: inset 2px 0 0 0 ${theme.colors.primary[500]},
            -2px 0 0 0 ${theme.colors.primary[500]};
        }
      }
    }
  `}
`;
export const TabsContentStyled = styled(TabsContent)`
  ${({ theme }) => css`
    flex-grow: 1;
    outline: none;
  `}
`;
