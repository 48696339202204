'use client';

import { useCallback, useState, useEffect } from 'react';

import Link from 'next/link';
import { useParams, useRouter } from 'next/navigation';

import { useListProjectsByUser } from '@/hooks/project/use-list-projects-by-user';
import { useAuth } from '@/providers/auth-provider';
import { ListProjectsByUserProps } from '@/services/project/get-list-projects-by-user';
import { Close, PeopleAltOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';

import { AccordionComponent, Button, getMenuOptions } from '@/components';
import { AvatarImage } from '@/components';

import * as S from './styles';

interface MenuListItems {
  href: string;
  label?: string;
  description?: string;
  icon?: React.ReactNode;
}
interface MenuListProps {
  items?: MenuListItems[];
}

type NavMenuProps = {
  hasOrganization: boolean;
  hasProject?: boolean;
  organizationName?: string;
  threeProjects?: MenuListItems[];
  redirectViewAllProjects: string;
  redirectAddProject: string;
  isError?: boolean;
  projectList: ListProjectsByUserProps[];
  handleRedirectUserToAddProject: () => void;
};

const MenuList: React.FC<MenuListProps & { onCloseMenu: () => void }> = ({
  items,
  onCloseMenu,
}) => {
  const handleClickItem = useCallback(() => {
    onCloseMenu();
  }, [onCloseMenu]);

  return (
    <S.ContentList>
      <S.ListUl>
        {items?.map((item, index) => (
          <S.ListLi key={index} onClick={handleClickItem}>
            {item.icon}
            <S.ListLink href={item.href}>
              {item.label}
              <S.ListDescription>{item.description}</S.ListDescription>
            </S.ListLink>
          </S.ListLi>
        ))}
      </S.ListUl>
    </S.ContentList>
  );
};

const NavMenu = ({
  hasOrganization,
  organizationName,
  isError,
  handleRedirectUserToAddProject,
  hasProject,
  projectList,
}: NavMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();
  const { organizationId = '', projectId = '' } = useParams();
  const { userAssiny } = useAuth();
  const projectSelected = projectList?.find((project) => {
    if (project.projectId === projectId) {
      return project;
    }
  });

  const { data: dataProjects } = useListProjectsByUser(
    userAssiny.id,
    organizationId as string,
    []
  );

  const isOwnerFromCurrentProject =
    dataProjects?.find((project) => project.projectId === projectId)?.role ===
    'OWNER';

  const optionsMenu = getMenuOptions({
    organizationId: organizationId as string,
    projectId: projectId as string,
    isProjectOwner: isOwnerFromCurrentProject,
  });

  const handleMenuClick = useCallback(() => {
    setIsMenuOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('menuOpen');
    } else {
      document.body.classList.remove('menuOpen');
    }
  }, [isMenuOpen]);

  return (
    <S.NavContainer data-testid="nav-header">
      <button
        onClick={handleMenuClick}
        className="menucursor"
        data-testid="menu-button"
        data-menu-open={isMenuOpen}>
        <MenuIcon />
      </button>

      {isMenuOpen && (
        <S.NavMenu data-testid="nav-menu">
          <S.ButtonClose onClick={handleMenuClick}>
            <Close />
          </S.ButtonClose>
          {!isError && hasOrganization && (
            <section className="wrapperInfoUser">
              <>
                <div className="wrapperOrganization">
                  <PeopleAltOutlined aria-label="Organização" />
                  <label className="organizationName">{organizationName}</label>
                </div>
                <div className="wrapperProject">
                  {hasProject ? (
                    <S.ProjectsButtonContainer onClick={handleMenuClick}>
                      <S.ProjectsButton
                        element={Link}
                        href={`/auth/organizations/${organizationId}/projects`}
                        icon={
                          <AvatarImage
                            className="avatarImage"
                            size="sm"
                            name={
                              projectSelected?.projectName
                                ? projectSelected?.projectName
                                : 'Projetos'
                            }
                          />
                        }
                        className="button"
                        color="white"
                        radius="circle"
                        size="md">
                        {projectSelected?.projectName
                          ? projectSelected?.projectName
                          : 'Ver projetos'}
                      </S.ProjectsButton>
                    </S.ProjectsButtonContainer>
                  ) : (
                    <Button
                      type="button"
                      color="secondary"
                      size="sm"
                      onClick={() => {
                        handleRedirectUserToAddProject();
                        handleMenuClick();
                      }}
                      className="wrapperOrganization">
                      Adicionar novo projeto
                    </Button>
                  )}
                </div>
              </>
            </section>
          )}

          {optionsMenu.optionsProject &&
            optionsMenu.optionsProject?.length > 0 && (
              <AccordionComponent
                size="lg"
                label="Gestão"
                description={
                  <MenuList
                    items={optionsMenu.optionsProject}
                    onCloseMenu={handleMenuClick}
                  />
                }
                style={{ width: '100%' }}
              />
            )}
          <AccordionComponent
            size="lg"
            label="Análise"
            description={
              <MenuList
                items={optionsMenu.optionsProfile}
                onCloseMenu={handleMenuClick}
              />
            }
            style={{ width: '100%' }}
          />
          <S.ButtonPress
            onClick={() =>
              router.push(
                `/auth/organizations/${organizationId}/projects/${projectId}/integrations`
              )
            }>
            Integrações
          </S.ButtonPress>
        </S.NavMenu>
      )}
    </S.NavContainer>
  );
};

export { NavMenu };
