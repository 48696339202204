import { SchemaLoginProps } from '@/utils/schema/login';
import { getApps, initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  deleteUser,
  EmailAuthProvider,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
  User,
} from 'firebase/auth';
import { setCookie } from 'nookies';

export type AuthErrorCode =
  | 'auth/user-not-found'
  | 'auth/wrong-password'
  | 'auth/too-many-requests'
  | 'auth/network-request-failed'
  | 'auth/invalid-action-code'
  | 'auth/user-disabled'
  | 'auth/weak-password'
  | 'auth/email-already-in-use'
  | 'auth/invalid-email'
  | 'auth/operation-not-allowed'
  | 'auth/weak-password';

export type AuthProviderType = 'password' | 'google' | 'microsoft';
export type AuthCredentials = SchemaLoginProps;
export type SchemaSendResetEmailProps = {
  email: string;
};
export type SchemaresetUserPasswordProps = {
  oobCode: string;
  newPassword: string;
};

export class AuthError extends Error {
  constructor(public code: AuthErrorCode, public message: string) {
    super(message);
    this.name = 'AuthError';
    this.code = code;
    this.message = this.getAuthErrorMessage();
  }

  getAuthErrorMessage() {
    const errorCodeToMessage: Record<AuthErrorCode, string> = {
      // eslint-disable-next-line prettier/prettier
      'auth/weak-password':
        'Senha muito fraca. Tente uma senha mais forte utilizando letras, números e caracteres especiais',
      'auth/email-already-in-use': 'E-mail já cadastrado',
      'auth/invalid-email': 'E-mail inválido',
      'auth/user-not-found': 'Usuário não encontrado',
      'auth/wrong-password': 'Senha incorreta',
      'auth/too-many-requests': 'Muitas tentativas de login. Tente mais tarde',
      'auth/network-request-failed': 'Sem conexão com a internet',
      'auth/invalid-action-code': 'Código de ação inválido',
      'auth/user-disabled': 'Usuário desabilitado',
      'auth/operation-not-allowed': 'Operação não permitida',
    };

    return errorCodeToMessage[this.code] || 'Aconteceu um erro desconhecido';
  }
}

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
};

const app =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export const appAuth = getAuth(app);

const setCookies = ({ userId, token }: { userId: string; token: string }) => {
  setCookie(null, 'userId', userId, {
    path: '/',
  });
  setCookie(null, 'token', token, {
    path: '/',
  });
};

export const signInAPI = async (user: User) => {
  try {
    const userIdToken = await user.getIdToken(true);
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userIdToken}`,
      },
      cache: 'no-store',
    });

    if (response.status !== 200) {
      throw new Error('Response status from /api/login is not 200');
    }

    setCookies({
      token: userIdToken,
      userId: user.uid,
    });
  } catch (error) {
    throw new Error('Error signing in');
  }
};

export async function redirectSignIn() {
  try {
    const userCred = await getRedirectResult(appAuth);
    if (!userCred) {
      throw new Error('Error signing in');
    }

    return userCred.user;
  } catch (error) {
    console.error(error);
  }
}

export async function signInWithProvider(
  authProvider: AuthProviderType,
  credentials?: SchemaLoginProps
) {
  try {
    let authResponse;

    if (authProvider === 'password') {
      if (!credentials) {
        throw new Error('Invalid credentials');
      }
      const persistence = credentials.rememberMe
        ? browserLocalPersistence
        : browserSessionPersistence;
      await appAuth.setPersistence(persistence);
      authResponse = await signInWithEmailAndPassword(
        appAuth,
        credentials.email,
        credentials.password
      );
    } else if (authProvider === 'google') {
      const provider = new GoogleAuthProvider();
      authResponse = await signInWithPopup(appAuth, provider);
    } else if (authProvider === 'microsoft') {
      const provider = new OAuthProvider('microsoft.com');
      authResponse = await signInWithPopup(appAuth, provider);
    } else {
      throw new Error('Invalid provider');
    }

    const { user } = authResponse;

    return { user };
  } catch (error) {
    const authError = error as AuthError;
    throw new AuthError(authError.code, authError.message);
  }
}

export const signUpPassword = async (data: AuthCredentials) => {
  try {
    const { user } = await createUserWithEmailAndPassword(
      appAuth,
      data.email,
      data.password
    );

    return { user };
  } catch (error) {
    const authError = error as AuthError;
    throw authError;
  }
};

export default async function signOut() {
  try {
    await appAuth.setPersistence(browserSessionPersistence);
    await appAuth.signOut();

    await fetch('/api/signout', {
      method: 'POST',
    });

    return true;
  } catch (error) {
    console.error(error);
  }
}

export async function sendResetEmail({ email }: SchemaSendResetEmailProps) {
  try {
    await sendPasswordResetEmail(appAuth, email);
  } catch (error) {
    const authError = error as AuthError;
    throw new AuthError(authError.code, authError.message);
  }
}

export async function resetUserPassword(oobCode: string, newPassword: string) {
  try {
    await confirmPasswordReset(appAuth, oobCode, newPassword);

    return;
  } catch (error) {
    const authError = error as AuthError;
    throw new AuthError(authError.code, authError.message);
  }
}

export async function reauthenticateUserWithCredential(
  provider: string,
  password?: string
) {
  const user = appAuth.currentUser as User;
  if (!user.email) {
    throw new AuthError('auth/invalid-email', 'No email available');
  }

  const providerTeste = new OAuthProvider(provider);

  if (password) {
    const credentialEmailAndPassword = EmailAuthProvider.credential(
      user?.email,
      password
    );

    await reauthenticateWithCredential(user, credentialEmailAndPassword);
    return;
  }

  await reauthenticateWithPopup(user, providerTeste);
}

export async function updateUserPassword(
  password: string,
  newPassword: string
) {
  const user = appAuth.currentUser as User;
  if (!user.email) {
    throw new AuthError('auth/invalid-email', 'No email available');
  }

  const credential = EmailAuthProvider.credential(user.email, password);

  try {
    await reauthenticateWithCredential(user, credential);
    await updatePassword(appAuth.currentUser as User, newPassword);
    return true;
  } catch (error) {
    const authError = error as AuthError;
    throw new AuthError(authError.code, authError.message);
  }
}

export async function deleteUserAccount() {
  const user = appAuth.currentUser as User;

  try {
    await deleteUser(user as User);

    return;
  } catch (error) {
    const authError = error as AuthError;
    throw new AuthError(authError.code, authError.message);
  }
}
