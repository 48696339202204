'use client';

import { useEffect } from 'react';
import {
  FieldValue,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import 'react-quill/dist/quill.snow.css';

import dynamic from 'next/dynamic';

import * as S from './styles';

const ReactQuill = dynamic(() => import('react-quill'), { ssr: false });

type EditorProps = {
  name: string;
  label: string;
  register: UseFormRegister<FieldValue<FieldValues>>;
  setValue: UseFormSetValue<FieldValue<FieldValues>>;
  watch: UseFormWatch<FieldValue<FieldValues>>;
  error?: boolean;
  errorMessage?: string;
};

export const Editor = ({
  name,
  label,
  register,
  setValue,
  watch,
  error,
  errorMessage,
}: EditorProps) => {
  useEffect(() => {
    register(name, { required: true, minLength: 15 });
  }, [register]);

  const onEditorStateChange = (editorState: string) => {
    setValue(name, editorState);
  };

  const editorContent = watch(name);

  return (
    <>
      {label && <S.Label>{label}</S.Label>}
      <S.EditorContainer>
        <ReactQuill
          theme="snow"
          value={editorContent}
          onChange={onEditorStateChange}
        />
      </S.EditorContainer>
      {error && errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
    </>
  );
};
