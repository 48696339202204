import {
  ListProjectsByUserProps,
  getListProjectsByUser,
} from '@/services/project/get-list-projects-by-user';
import { mapperListProjectsByUser } from '@/utils/mappers/project/mapper-get-project-by-user';
import { objectIsEmpty } from '@/utils/validate/object-is-empty';
import { useQuery } from '@tanstack/react-query';

export const useListProjectsByUser = (
  userId: string,
  organizationId: string,
  initialData?: [] | ListProjectsByUserProps[],
  projectName?: string
) => {
  return useQuery({
    queryKey: [
      'projects-by-userId',
      userId + '-' + organizationId,
      projectName,
    ],
    queryFn: async () => {
      const returnList = await getListProjectsByUser(
        userId,
        organizationId,
        projectName
      );

      const returnListMapper = mapperListProjectsByUser(
        objectIsEmpty(returnList)
          ? []
          : (returnList as ListProjectsByUserProps[])
      );
      return returnListMapper;
    },
    initialData: initialData,
  });
};
