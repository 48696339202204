import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    overflow-x: auto;

    ${theme.breakpoints.tablet720} {
      overflow: hidden;
    }
  `}
`;

export const StyledPagination = styled.ul`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    vertical-align: middle;
    user-select: none;
    width: fit-content;

    ${theme.breakpoints.mobile360} {
      margin: 0 auto;
      width: 100%;
    }
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ${theme.spacing['40']};
    height: ${theme.spacing['40']};
    border: ${theme.colors.gray[200]};
    color: ${theme.colors.gray['700']};
    border: 1px solid ${theme.colors.gray['500']};
    font-weight: ${theme.typography.fontWeight.semiBold};
    background-color: ${theme.colors.white};

    &:disabled {
      background-color: ${theme.colors.white};
    }
    &:hover {
      background-color: ${theme.colors.gray['300']};
    }
    &.primary {
      border-radius: 8px 0px 0px 8px;
      color: ${theme.colors.gray['700']};
      border-right: none;
      width: auto;
    }
    &.secondary {
      border-top: 1px solid ${theme.colors.gray['500']};
      border-bottom: 1px solid ${theme.colors.gray['500']};
      border-left: 1px solid ${theme.colors.gray['500']};
      border-right: none;
      color: ${theme.colors.gray['700']};
    }
    &.tertiary {
      width: auto;
      border-radius: 0px 8px 8px 0px;
      color: ${theme.colors.gray['700']};
    }
    & > .firstOne {
      align-items: center;
      justify-content: center;
      display: flex;
      padding: ${theme.spacing['10']} ${theme.spacing['16']};
      gap: ${theme.spacing['08']};
      svg {
        fill: ${theme.colors.gray['700']};
      }
    }
  `}
`;

export const List = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`;
