'use client';

import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useRouter, usePathname } from 'next/navigation';

import {
  appAuth,
  AuthCredentials,
  AuthError,
  AuthProviderType,
  deleteUserAccount,
  reauthenticateUserWithCredential,
  redirectSignIn,
  SchemaSendResetEmailProps,
  sendResetEmail,
  signInAPI,
  signInWithProvider,
  signUpPassword,
  updateUserPassword,
  resetUserPassword,
} from '@/config/firebase/auth';
import { useSearchUser } from '@/hooks/user/use-search-user';
import { UserProps } from '@/utils/enums/user';
import { SchemaForgotProps } from '@/utils/schema/forgot';
import { SchemaLoginProps } from '@/utils/schema/login';
import { User, onAuthStateChanged, updateProfile } from 'firebase/auth';

export type AuthProviderProps = {
  children: ReactNode;
};

interface AuthContextType {
  user: User;
  userAssiny: UserProps;
  isLoading: boolean;
  updateUserName: (name: string) => Promise<void>;
  signIn: (
    authProvider: AuthProviderType,
    credentials?: AuthCredentials
  ) => Promise<void>;
  signInWithPassword: (data: SchemaLoginProps) => Promise<void>;
  deleteUser: () => Promise<void>;
  updatePassword: (password: string, newPassword: string) => Promise<void>;
  reauthenticateUser: (provider: string, password?: string) => Promise<void>;
  forgotPassword: (email: SchemaForgotProps) => Promise<void>;
  resetPassword: (oobCode: string, newPassword: string) => Promise<void>;
}

// Todo: Refatorar esse lógica para não usar esses console.log

export const AuthContext = createContext<AuthContextType>({
  user: {} as User,
  userAssiny: {} as UserProps,
  isLoading: true,
  updateUserName: async () => {
    console.log('Updating user name');
  },
  signIn: async () => {
    console.log('Handling Login With Provider');
  },
  signInWithPassword: async () => {
    console.log('Handling Login With Password');
  },
  deleteUser: async () => {
    console.log('Delete user account');
  },
  updatePassword: async () => {
    console.log('Updating user password');
  },
  reauthenticateUser: async () => {
    console.log('Reauthenticate user');
  },
  forgotPassword: async () => {
    console.log('Send forgot password');
  },
  resetPassword: async () => {
    console.log('Reset user password');
  },
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState({} as User);
  const [userAssiny, setUserAssiny] = useState({} as UserProps);
  const [isLoading, setIsLoading] = useState(true);
  const { mutateAsync: fetchUserData } = useSearchUser();
  const router = useRouter();
  const pathname = usePathname();

  const redirectUser = (loggedInUser: User) => {
    if (!loggedInUser) {
      router.push('/login');
      return;
    } else if (!loggedInUser.displayName) {
      router.push('/register');
      return;
    } else if (pathname.includes('/login') || pathname.includes('/register')) {
      router.push(`/auth?userId=${loggedInUser.uid}`);
      return;
    }

    router.refresh();
  };

  const signIn = async (
    authProvider: AuthProviderType,
    credentials?: AuthCredentials
  ) => {
    setIsLoading(true);

    try {
      const response = await signInWithProvider(authProvider, credentials);
      setUser(response?.user);

      const userData = await fetchUserData(response?.user?.uid);
      setUserAssiny(userData as UserProps);
    } finally {
      setIsLoading(false);
    }
  };

  const signInWithPassword = async (data: AuthCredentials) => {
    setIsLoading(true);

    try {
      const response = await signUpPassword(data);
      setUser(response?.user);
    } finally {
      setIsLoading(false);
    }
  };

  const updatePassword = async (password: string, newPassword: string) => {
    setIsLoading(true);
    try {
      await updateUserPassword(password, newPassword);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserName = async (name: string) => {
    if (appAuth && appAuth.currentUser) {
      await updateProfile(appAuth.currentUser, {
        displayName: name,
      });
    }
  };

  const reauthenticateUser = async (provider: string, password?: string) => {
    setIsLoading(true);
    try {
      if (password) {
        await reauthenticateUserWithCredential(provider, password);
        return;
      }

      await reauthenticateUserWithCredential(provider);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteUser = async () => {
    setIsLoading(true);

    try {
      await deleteUserAccount();
    } finally {
      setIsLoading(false);
    }
  };

  const forgotPassword = async (email: SchemaSendResetEmailProps) => {
    setIsLoading(true);

    try {
      await sendResetEmail(email);
    } finally {
      setIsLoading(false);
    }
  };

  const resetPassword = async (oobCode: string, newPassword: string) => {
    setIsLoading(true);

    try {
      await resetUserPassword(oobCode, newPassword);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(appAuth, async (currentUser) => {
      if (!currentUser) {
        setUser({} as User);
        setUserAssiny({} as UserProps);
        if (pathname.includes('/auth/') && !pathname.includes('/signout')) {
          router.push('/signout');
        }
        return;
      }

      try {
        await signInAPI(currentUser);
        setUser(currentUser);

        const userData = await fetchUserData(currentUser?.uid);
        setUserAssiny(userData as UserProps);

        redirectUser(currentUser);
      } catch (error) {
        const authError = error as AuthError;
        throw new AuthError(authError.code, authError.message);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const getInitialSignIn = async () => {
      try {
        await redirectSignIn();
      } finally {
        setIsLoading(false);
      }
    };
    getInitialSignIn();
  }, []);

  const authValues = {
    user,
    userAssiny,
    isLoading,
    updateUserName,
    updatePassword,
    resetPassword,
    deleteUser,
    reauthenticateUser,
    forgotPassword,
    signIn,
    signInWithPassword,
  };

  return (
    <AuthContext.Provider value={authValues}>{children}</AuthContext.Provider>
  );
};
