import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled, { css } from 'styled-components';

export const DropdownTrigger = styled(DropdownMenu.Trigger)`
  && {
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
`;

export const Container = styled.div<{ category: string }>`
  ${({ theme, category }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing['10']} ${theme.spacing['16']};
    gap: ${theme.spacing['08']};
    z-index: 9;
    ${category === 'account' &&
    css`
      padding: ${theme.spacing['08']} ${theme.spacing['16']}
        ${theme.spacing['20']} ${theme.spacing['16']};
      justify-content: center;
    `}

    ${category !== 'account' &&
    css`
      border: none;
    `}
  `}
`;

export const Text = styled.div<{ category: string }>`
  ${({ theme, category }) => css`
    color: ${theme.colors.gray['900']};
    font-family: ${theme.typography.fontFamily.inter};
    font-size: ${theme.typography.fontSize['14'].size};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.medium};
    line-height: ${theme.spacing['20']};

    ${category === 'danger' &&
    css`
      color: ${theme.colors.error['600']};
    `}
    ${category === 'multi-line' &&
    css`
      color: ${theme.colors.gray['900']};
    `}
    ${category === 'account' &&
    css`
      color: ${theme.colors.gray['900']};
      font-weight: ${theme.typography.fontWeight.bold};
    `}
    ${category === 'category' &&
    css`
      color: ${theme.colors.gray['900']};
      font-size: ${theme.typography.fontSize['12'].size};
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
    `}
  `}
`;

export const SubText = styled.div<{ category: string }>`
  ${({ theme, category }) => css`
    color: ${theme.colors.gray['500']};
    font-family: ${theme.typography.fontFamily.inter};
    font-size: ${theme.typography.fontSize['12'].size};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.regular};
    line-height: 16px;

    ${category === 'account' &&
    css`
      color: ${theme.colors.gray['700']};
    `}
  `}
`;

export const ListItem = styled(DropdownMenu.Item)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 250px;
    gap: ${theme.spacing['08']};
    padding: ${theme.spacing['10']} ${theme.spacing['16']};
    cursor: pointer;

    &:first-child {
      border-radius: ${theme.radius.md} ${theme.radius.md} 0 0;
    }

    &:last-child {
      border-radius: 0 0 ${theme.radius.md} ${theme.radius.md};
    }

    &:last-child {
      &.customLastItem {
        border-top: 1px solid ${theme.colors.gray[200]};
        /* border-radius: unset; */
        color: ${theme.colors.error[600]};
      }
    }

    &:hover {
      background-color: ${theme.colors.gray[100]};
    }
  `}
`;

export const DropdownContent = styled(DropdownMenu.Content)`
  ${({ theme }) => css`
    border-radius: ${theme.radius.md};
    width: 250px;
    background: ${theme.colors.white};
    box-shadow: ${theme.shadows.xl};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const AccountImage = styled.img<{ src: string }>`
  width: 40px;
  height: 40px;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  object-fit: cover;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

export const DividerLine = styled.hr`
  ${({ theme }) => css`
    width: 250px;
    height: 1px;
    background-color: ${theme.colors.gray['900']};
    border: none;
  `}
`;
