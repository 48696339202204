import { Root, Thumb } from '@radix-ui/react-switch';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing['16']};
  `}
`;

export const SwitchRoot = styled(Root)`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.gray[300]};
    border-radius: ${theme.radius.sm};
    border: none;
    display: flex;

    &.size-sm {
      height: ${theme.spacing[20]};
      width: 36px;
    }

    &.size-md {
      height: ${theme.spacing[24]};
      padding: 2px;
      width: 44px;
    }

    &[data-disabled] {
      border-color: ${theme.colors.gray[200]};
      cursor: not-allowed;
    }

    &[data-state='checked'] {
      background-color: ${theme.colors.accent[500]};
      border-color: ${theme.colors.accent[500]};
    }

    &:hover:not([data-disabled]) {
      border-color: ${theme.colors.accent[500]};
      box-shadow: 0px 0px 0px 4px #f2f4f7;
    }

    &:focus:not([data-disabled]) {
      border-color: ${theme.colors.accent[500]};
      box-shadow: 0 0 0 4px ${theme.colors.gray[100]};
      outline: none;
    }
  `}
`;

export const SwitchThumb = styled(Thumb)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: ${theme.radius.circle};
    display: flex;
    transition: transform 100ms;
    will-change: transform;

    &.size-sm {
      height: ${theme.spacing[16]};
      transform: translateX(2px);
      width: ${theme.spacing[16]};
    }

    &.size-md {
      height: ${theme.spacing[20]};
      width: ${theme.spacing[20]};

      &[data-state='checked']:not([data-disabled]) {
        transform: translateX(${theme.spacing['20']});
      }
    }

    &[data-disabled] {
      background-color: ${theme.colors.gray[100]};
      cursor: not-allowed;
    }

    &[data-state='checked'] {
      transform: translateX(18px);
    }

    &:focus:not([data-disabled]) {
      border-color: ${theme.colors.accent[500]};
      box-shadow: 0 0 0 4px ${theme.colors.gray[100]};
      outline: none;
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSize['14'].size};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
    font-weight: ${theme.typography.fontWeight.medium};
    color: ${theme.colors.gray[900]};
  `}
`;
