import {
  Close,
  Content,
  Description,
  Overlay,
  Title,
} from '@radix-ui/react-dialog';
import styled, { css, keyframes } from 'styled-components';

const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const WrapperBadge = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border-radius: ${theme.radius.circle};
    display: flex;
    height: ${theme.spacing[48]};
    justify-content: center;
    width: ${theme.spacing[48]};

    &.status-success {
      border: ${theme.spacing['04']} solid ${theme.colors.success['50']};
      background: ${theme.colors.success['100']};
    }

    &.status-error {
      border: ${theme.spacing['04']} solid ${theme.colors.error['50']};
      background: ${theme.colors.error['100']};
    }

    &.status-warning {
      border: ${theme.spacing['04']} solid ${theme.colors.warning['50']};
      background: ${theme.colors.warning['100']};
    }

    &.position-center {
      margin: 0 auto;
    }
  `}
`;

export const OverlayComponent = styled(Overlay)<{ zIndex?: number }>`
  ${({ zIndex }) => css`
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    inset: 0;
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: ${zIndex || 1};

    &.background-unset {
      background: unset;
    }

    &.background-blur {
      backdrop-filter: blur(2px);
    }
  `}
`;

export type Position =
  | ['top', 'left']
  | ['top', 'right']
  | ['bottom', 'left']
  | ['bottom', 'right']
  | undefined;
export const ContentComponent = styled(Content)<{
  $position?: Position;
  $zIndex?: number;
}>`
  ${({ theme, $position, $zIndex }) => css`
    position: fixed;
    box-shadow: ${theme.shadows.xl};
    background-color: ${theme.colors.white};
    padding: ${theme.spacing['24']};
    max-width: 450px;
    border-radius: ${theme.radius.sm};
    animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: ${$zIndex || 2};

    &.sm {
      max-width: 500px;
    }

    &.md {
      max-width: 520px;
    }

    &.position-left {
      text-align: left;
    }

    &.position-center {
      text-align: center;
    }
    ${$position
      ? css`
          bottom: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          margin: 0;
          width: 100%;
          max-height: 60vh;
          top: unset;
          right: 0;
          left: unset;
          transform: translate(0, 0);
          overflow-y: auto;

          ${({ theme }) => theme.breakpoints.tablet720} {
            ${({ theme }) => css`
              border-radius: ${theme.radius.sm};
              max-height: 100vh;
              ${$position[0] === 'top' &&
              $position[1] === 'left' &&
              css`
                top: 0;
                bottom: unset;
              `}
              ${$position[0] === 'top' &&
              $position[1] === 'right' &&
              css`
                top: 0;
                bottom: unset;
                border-radius: ${theme.radius.sm};
              `}
              ${$position[0] === 'bottom' &&
              $position[1] === 'left' &&
              css`
                bottom: 0;
                border-radius: ${theme.radius.sm};
              `}
              ${$position[0] === 'bottom' &&
              $position[1] === 'right' &&
              css`
                bottom: 0;
                border-radius: ${theme.radius.sm};
              `}
            `}
          }
        `
      : css`
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90vw;
          margin: 0 auto;
          max-height: 90vh;
        `}
  `}
`;

export const TitleComponent = styled(Title)`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.inter};
    font-weight: ${theme.typography.fontWeight.bold};
    font-size: ${theme.typography.fontSize['18'].size};
    line-height: ${theme.typography.fontSize['18'].lineHeight};
    color: ${theme.colors.gray['900']};
    max-width: 320px;
    margin-bottom: ${theme.spacing['08']};
    margin-top: ${theme.spacing['20']};

    &.position-center {
      text-align: center;
    }
  `}
`;

export const TitleRegister = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSize['24'].size};
    line-height: ${theme.typography.fontSize['30'].lineHeight};
    font-weight: ${theme.typography.fontWeight.bold};
    color: ${theme.colors.gray['900']};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing['08']};

    ${theme.breakpoints.tablet720} {
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      flex-direction: row;
    }
  `}
`;

export const DescriptionComponent = styled(Description)`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.inter};
    font-weight: ${theme.typography.fontWeight.regular};
    font-size: ${theme.typography.fontSize['14'].size};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
    color: ${theme.colors.gray['500']};

    &.position-center {
      text-align: center;
    }
  `}
`;

export const DescriptionRegister = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily.inter};
    font-weight: ${theme.typography.fontWeight.regular};
    font-size: ${theme.typography.fontSize['14'].size};
    line-height: ${theme.typography.fontSize['20'].lineHeight};
    color: ${theme.colors.gray['700']};
  `}
`;

export const CloseComponent = styled(Close)`
  ${({ theme }) => css`
    height: 24px;
    width: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #667085;
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: ${theme.colors.white};
  `}
`;

export const ContainerButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing['12']};
    margin-top: ${theme.spacing['32']};

    ${theme.breakpoints.tablet720} {
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;

      & > button {
        width: 100%;
      }
    }

    & > button {
      height: 44px;
    }
  `}
`;

export const WrapperFooter = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing['16']};
    margin-top: ${theme.spacing['16']};

    > button {
      flex: 1;
    }

    ${theme.breakpoints.tablet960} {
      flex-direction: row-reverse;
    }
  `}
`;

export const WrapperDivider = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin: ${theme.spacing['16']} -${theme.spacing['24']};

    hr {
      background-color: ${theme.colors.gray['200']};
    }
  `}
`;
