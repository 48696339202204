import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import * as Toast from '@radix-ui/react-toast';
import styled, { css } from 'styled-components';

export const ToastRoot = styled(Toast.Root)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacing['08']};
    border-radius: ${theme.radius.xs};
    box-shadow: ${theme.shadows.xl};
    padding: ${theme.spacing['12']} ${theme.spacing['16']};
    min-width: 256px;

    & > button {
      height: ${theme.spacing[20]};
    }

    &.contrast-high {
      background-color: ${theme.colors.black};
      color: ${theme.colors.white};
    }

    &.contrast-low {
      background-color: ${theme.colors.white};
      color: ${theme.colors.black};
    }
  `}
`;

export const Title = styled(Toast.Title)`
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeight.medium};
    font-size: ${theme.typography.fontSize['14'].size};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing['08']};

    &.contrast-high {
      color: ${theme.colors.white};
    }

    &.contrast-low {
      color: ${theme.colors.black};
    }
  `}
`;

export const ToastViewport = styled(Toast.Viewport)`
  ${({ theme }) => css`
    --viewport-padding: ${theme.spacing[24]};
    position: fixed;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing[24]};

    &.position-top-left {
      top: 0;
      left: 0;
    }

    &.position-top-right {
      top: 0;
      right: 0;
    }

    &.position-bottom-left {
      bottom: 0;
      left: 0;
    }

    &.position-bottom-right {
      bottom: 0;
      right: 0;
    }
  `}
`;

export const SvgIconBadgeInfo = styled(InfoOutlinedIcon)`
  ${({ theme }) => css`
    color: ${theme.colors.accent[500]};
  `}
`;

export const SvgIconBadgeSuccess = styled(CheckCircleOutlineOutlinedIcon)`
  ${({ theme }) => css`
    color: ${theme.colors.success[700]};
  `}
`;

export const SvgIconBadgeError = styled(ErrorOutlineIcon)`
  ${({ theme }) => css`
    color: ${theme.colors.error[700]};
  `}
`;

export const SvgIconBadgeWarning = styled(WarningAmberIcon)`
  ${({ theme }) => css`
    color: ${theme.colors.warning[700]};
  `}
`;
