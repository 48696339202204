import { UserProps } from '@/utils/enums/user';
import { request } from '@/utils/request';

const url = process.env.NEXT_PUBLIC_BFF;

export const getUser = async (firebaseId: string) => {
  return await request<UserProps>({
    url: url + '/user/' + firebaseId,
    method: 'GET',
    cache: 'no-store',
  });
};
