import { ReactNode } from 'react';

import {
  RadioGroupProps as RadixRadioGroupProps,
  RadioGroupItemProps as RadixRadioGroupItemProps,
} from '@radix-ui/react-radio-group';

import * as S from './styles';

type RadioGroupProps = {
  children: ReactNode;
  orientation?: 'vertical' | 'horizontal';
  defaultValue?: string;
} & RadixRadioGroupProps;

type RadioGroupItem = {
  value: string;
  displayValue: string;
  description?: string;
  inverse?: boolean;
  id: string;
} & RadixRadioGroupItemProps;

const RadioGroup = ({
  children,
  orientation = 'horizontal',
  defaultValue,
  ...props
}: RadioGroupProps) => (
  <S.RadioGroupRoot
    defaultValue={defaultValue}
    orientation={orientation}
    {...props}>
    {children}
  </S.RadioGroupRoot>
);

const RadioGroupItem = ({
  value,
  id,
  displayValue,
  description,
  inverse = false,
  ...props
}: RadioGroupItem) => (
  <S.RadioGroupItemContainer $inverse={inverse}>
    <S.RadioGroupItem value={value} id={id} {...props}>
      <S.RadioGroupIndicator />
    </S.RadioGroupItem>

    <S.Label
      htmlFor={id}
      $bold={!!description}
      className={`radio-group-label-${value}`}>
      <span>{displayValue}</span>
      <S.Description>{description}</S.Description>
    </S.Label>
  </S.RadioGroupItemContainer>
);

export { RadioGroup, RadioGroupItem };
