import { DragEvent, useEffect, useState } from 'react';
import { Control, Controller, FieldValue, FieldValues } from 'react-hook-form';

import { isValidUrl } from '@/utils/string';
import { ImageOutlined as ImageIcon } from '@mui/icons-material';

import * as S from './styles';

type ImageInputProps = {
  label?: string;
  name?: string;
  control?: Control<FieldValue<FieldValues>>;
  error?: boolean;
  errorMessage?: string;
  defaultValue?: string;
  resetPreview?: boolean;
};

export const ImageInput = ({
  label = '',
  control,
  name = 'image',
  error,
  errorMessage,
  defaultValue,
  resetPreview = false,
}: ImageInputProps) => {
  const [preview, setPreview] = useState<File | string>(defaultValue || '');

  const handleImage = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const loadedImage = files[0];
    setPreview(loadedImage);
  };

  const [windowWidth, setWindowWidth] = useState(0);

  const handleDragEnter = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
  };

  const handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    setPreview(file);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    setWindowWidth(window.innerWidth);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (resetPreview) {
      setPreview('');
    }
  }, [resetPreview]);

  return (
    <S.Container>
      <span>{label}</span>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange, ...field } }) => {
          if (isValidUrl(value)) {
            setPreview(value);
          }

          return (
            <S.ImageCanvas
              htmlFor={name}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDrop={(event) => {
                handleDrop(event);

                const loadedImage = event.dataTransfer.files[0];

                onChange(loadedImage ? loadedImage : '');
              }}>
              <input
                {...field}
                type="file"
                onChange={(event) => {
                  handleImage(event.nativeEvent);
                  onChange(event.target.files ? event.target.files[0] : '');
                }}
                name={name}
                id={name}
                accept="image/*"
                style={{ display: 'none' }}
              />

              {!preview ? (
                <S.NoImageText>
                  <ImageIcon className="icon" />
                  {windowWidth < 720 ? (
                    <span className="instruction">
                      <strong>Selecione do seu celular</strong>
                    </span>
                  ) : (
                    <span className="instruction">
                      <strong>Selecione do computador</strong> ou arraste para
                      cá
                    </span>
                  )}
                  <span className="supported-images">
                    PNG, JPG, JPEG até 10MB
                  </span>
                </S.NoImageText>
              ) : (
                <S.ImagePreview
                  src={
                    isValidUrl(preview)
                      ? (preview as string)
                      : URL.createObjectURL(preview as File)
                  }
                  alt="pré visualização da imagem enviada"
                />
              )}
            </S.ImageCanvas>
          );
        }}
      />
      {error && errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
    </S.Container>
  );
};
